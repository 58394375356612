<template>
  <div class="content-wrap">
    <div class="content-settlement">
      <div
        class="detail-cont"
        v-if="isSettlementReveiving"
        v-loading="settlementLoading"
      >
        <div class="cont-item">
          <label>结算单号：</label>
          <span>{{ settlement.sheetCode || "--" }}</span>
        </div>
        <div class="cont-item">
          <label>单据日期：</label>
          <span>{{ settlement.tradeTime || "--" }}</span>
        </div>
        <div class="cont-item">
          <label>需方主体：</label>
          <span>{{ settlement.buyerMainName || "--" }}</span>
        </div>
        <div class="cont-item">
          <label>供方主体：</label>
          <span>{{ settlement.sellerMainName || "--" }}</span>
        </div>
      </div>
    </div>
    <div class="content-select">
      <div class="select-upload" v-if="submits.length < 1">
        <div class="select-left">
          <div>
            <span>收票组织：</span>
            <organization-select
              :model="org"
              allLabel="请选择收票组织"
              :is-add-all="false"
            />
          </div>
        </div>
        <div class="select-right">
          <div style="margin-right: 20px">
            <el-upload
              action="#"
              accept=".jpg,.jpeg,.png,.tif,.bmp,.JPG,.JPEG,.PNG,.TIF,.BMP,.xml,.pdf,.ofd"
              multiple
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handlerSelectFile"
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
          </div>
          <el-button
            type="primary"
            :disabled="!hasData"
            @click="duplicateChecking"
            >开始识别</el-button
          >
        </div>
      </div>
      <div class="collect_item_box" v-else>
        <div class="select-recognize">
          <i class="el-icon-info" style="color: #1890ff">&nbsp;&nbsp;</i>
          <div class="collect_item">
            <span>上传发票：</span>
            <span style="color: #1890ff">{{ totals }}</span
            >&nbsp;份
          </div>
          <div class="collect_item">
            <span>识别成功：</span>
            <span style="color: #4fb81b">{{ success }}</span
            >&nbsp;份
          </div>
          <div class="collect_item">
            <span>识别失败：</span>
            <span
              style="color: #f5222d; cursor: pointer"
              @click="handleFailureInvoice('F')"
              >{{ failure }}</span
            >&nbsp;份
          </div>
          <div class="collect_item">
            <span>识别重复：</span>
            <span
              style="color: #ff7a08; cursor: pointer"
              @click="handleFailureInvoice('R')"
              >{{ repeat }}</span
            >&nbsp;份
          </div>
        </div>
        <el-button type="text" @click="btnStatus = !btnStatus"
          >图表视图</el-button
        >
      </div>
    </div>

    <div class="picture-content">
      <div class="content-upload" v-if="submits.length < 1">
        <div class="exist-data" v-if="hasData">
          <div
            class="show-container"
            v-for="recognize in recognizes"
            :key="recognize.url"
          >
            <img
              src="@/assets/img/invoiceCenter/Close@2x.png"
              @click="handleDeleteShowInvoice(recognize.url)"
            />
            <div v-if="recognize.ifType == 'ifOfd'" class="show-invoice">
              <div class="xml_con_box">
                <div>OFD文件</div>
              </div>
            </div>
            <div v-else-if="recognize.ifType == 'ifXml'" class="show-invoice">
              <div class="xml_con_box">
                <div>XML文件</div>
              </div>
            </div>
            <div v-else class="show-invoice">
              <el-image
                :src="recognize.url"
                :preview-src-list="[recognize.url]"
              ></el-image>
            </div>
          </div>
        </div>
        <div class="xml_content" v-else-if="xmlContent.length">
          <div class="exist-data">
            <div
              class="show-container"
              v-for="(xml, idx) in xmlContent"
              :key="idx"
            >
              <!-- <img src="@/assets/img/invoiceCenter/Close@2x.png" style="cursor: pointer" @click="handleDeleteShowXMLInvoice(xml, idx)" /> -->
              <div class="show-invoice">
                <div class="xml_con_box">
                  <div>批次编号：{{ xml.batchNo }}</div>
                  <div>
                    识别结果：{{
                      xml.status == "SUCCESS"
                        ? "成功"
                        : xml.status == "REPEAT"
                        ? "重复"
                        : "失败"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="no-data" v-else>
          <img src="@/assets/img/invoiceCenter/nodata@2x.png" />
          <span>无发票数据！</span>
        </div>
      </div>
      <div class="content-recognize" v-if="submits.length && btnStatus">
        <div class="recognize-top">
          <div class="recognize-left">
            <label>识别批次：</label>
            <span>{{ batchNo }}</span>
          </div>
          <el-button
            type="primary"
            :disabled="isSubmit"
            @click="handleSubmitInvoice"
          >
            {{ buttonContent }}
          </el-button>
        </div>
        <div class="recognize-body">
          <invoice-recognize
            v-for="invoice in showSuccessInvoice"
            :invoice="invoice"
            :id="invoice.id"
            :images="showSuccessInvoiceImages"
            :key="invoice.id"
            @invoice-delete="handleRecognizedInvoiceDelete"
          >
          </invoice-recognize>
        </div>
      </div>
    </div>

    <div class="table_content" v-show="!btnStatus">
      <RecognizeFailureRecord
        ref="RecognizeFailureRecord"
        @_handlerDelete="_handlerDelete"
        @handlerBtnStatus="handlerBtnStatus"
        @getCurEditInvoiceIds="getCurEditInvoiceIds"
        :isComplete="isComplete"
        :isShowSearch="false"
        :from="'local_collect_invoice'"
        :curBatchNo="batchNo"
        :btnStatus="btnStatus"
        :unqueriedImageList="unqueriedImageList"
      />
    </div>

    <!-- 是否需要发票查重 -->
    <el-dialog
      title="提交提示"
      :visible.sync="dialogDuplicateCheckingIs"
      width="40%"
    >
      <span>是否需要发票查重？</span>
      <div style="color: red; margin: 20px 0">
        如果不查重,则会对发票文件进行补充. 可以选择要查重/不查重。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDuplicateCheckingIs = false">取 消</el-button>
        <el-button type="primary" @click="handleStartRecognize(false)"
          >要查重</el-button
        >
        <el-button type="primary" @click="handleStartRecognize(true)"
          >不查重</el-button
        >
      </span>
    </el-dialog>

    <!-- 提交成功弹窗 -->
    <el-dialog
      append-to-body
      width="30%"
      :visible.sync="successVisible"
      :show-close="false"
      :center="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-success">
        <span>提交成功</span>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="handleContinueUploadJX">继续上传</el-button>
        <el-button @click="handleFailureInvoice">处理失败发票</el-button>
        <el-button type="primary" @click="handleBackInvoiceCenter">{{
          successTitle
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 强制离开弹窗 -->
    <el-dialog
      append-to-body
      title="提示"
      width="400px"
      :visible.sync="recognizeLeave"
    >
      <div class="dialog-leave">
        当前识别的票据信息未提交，强制离开会导致发票信息丢失
      </div>
      <div class="dialog-footer" slot="footer" style="text-align: right">
        <el-button @click="handleFailureInvoice">强制离开</el-button>
        <el-button type="primary" @click="handleSubmitInvoice">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 提交 -->
    <div class="submit_Allow_style">
      <el-dialog
        title="发票清单不完整，是否继续提交？"
        :visible.sync="dialogSubmitAllow"
        center
        width="25%"
      >
        <span style="padding: 36px 20px">系统已标记出发票已有的清单</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogSubmitAllow = false">取 消</el-button>
          <el-button type="primary" @click="handlerSubmitNo">提 交</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="submit_Allow_style">
      <el-dialog
        title="发票清单不完整，请补全后提交"
        :visible.sync="dialogSubmitNotAllow"
        center
        width="25%"
      >
        <span>系统已标记出发票已有的清单</span>
        <span slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" @click="dialogSubmitNotAllow = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as imageConversion from "image-conversion";
import {
  changePdfToImage,
  getRecognizeBatchNo,
  recognizeInvoicePicture,
  submitInvoice,
  deleteImageInvoice,
  unqueriedImage,
  ofdRecognize,
} from "@/service/invoice-center";
import UploadOfdFile from "../person-wallet/components/UploadOfdFile";
import InvoiceImg from "@/components/invoice/InvoiceImg";
import InvoiceRecognize from "@/components/invoice/InvoiceRecognize";
import { commonsMethods } from "@/util/mixins";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import RecognizeFailureRecord from "./RecognizeFailureRecord";
import { uploadXMLFiles } from "@/service/tickets";
import { getTntConfig } from "@/service/system/config";
import { reqSelectSettlement } from "@/service/coo/trade.js";
export default {
  mixins: [commonsMethods],
  components: {
    InvoiceImg,
    InvoiceRecognize,
    UploadOfdFile,
    OrganizationSelect,
    RecognizeFailureRecord,
  },
  data() {
    return {
      dialogSubmitAllow: false,
      continueUploading: false,
      dialogSubmitNotAllow: false,
      allowSybmit: false,
      paramsData: null,
      isComplete: [],
      isFileUploading: false,
      dialogDuplicateCheckingIs: false,
      repetitionWhether: false,
      batchNoIs: true,
      module: "",
      loading: null,
      isSubmit: true,
      isSettlement: false,
      recognizes: [],
      recognizedCnt: 0, //已识别的总数
      submits: [], //待提交的票据
      successVisible: false,
      successTitle: "返回",
      batchNo: "",

      settlement: {},
      settlementId: "",
      settlementLoading: false,

      sceneName: "",
      sceneRole: "",

      isBlend: false,
      source: "CENTER",
      channel: "PICTURE",
      invoiceLabel: "ordinary",

      recognizeLeave: false,
      recognizeLeaveType: "",
      org: {
        orgId: undefined,
      },
      btnStatus: true, //true：图文模式 false：列表模式
      unqueriedImageList: [], //识别结果
      xmlContent: [], //xml识别结果
      xmlcount: 0,
      curEditIds: [], //在列表里编辑过的内容id
    };
  },
  computed: {
    hasData() {
      return this.recognizes.length > 0;
    },
    totals() {
      return this.submits.length;
    },
    success() {
      return this.submits.filter((item) => item.status == "SUCCESS").length;
    },
    failure() {
      return this.submits.filter(
        (item) => item.status == "FAILURE" || item.status == "UNMATCHED"
      ).length;
    },
    repeat() {
      // console.log(this.submits,'this.submits')
      return this.submits.filter((item) => item.status == "REPEAT").length;
    },
    showSuccessInvoice() {
      // console.log(this.submits.filter((item) => item.status == 'SUCCESS'),'----1111----')
      return this.submits.filter((item) => item.status == "SUCCESS");
    },
    showSuccessInvoiceImages() {
      return this.submits
        .filter((item) => item.status == "SUCCESS")
        .map((i) => i.recognizeImage);
    },
    isShowSettlement() {
      return this.channel == "SUPPLIER";
    },
    buttonContent() {
      return this.isSettlement ? "上传发票" : "提交";
    },
    isSettlementReveiving() {
      return !!this.settlementId;
    },
  },
  created() {
    const query = this.$route.query;
    this.module = query.module;
    let source = query.source;
    if (source) {
      this.source = source;
    }
    let channel = query.chennel;
    if (channel) {
      this.channel = channel;
    }
    if (query.settlementId) {
      this.settlementId = query.settlementId;
    }
    if (query.sceneName) {
      this.sceneName = query.sceneName;
    }
    if (query.sceneRole) {
      this.sceneRole = query.sceneRole;
    }

    this.org.orgId = this.$store.state.user.currUser.orgId;
    // console.log("this.org.orgId:", this.org.orgId);

    if (this.settlementId) {
      this.queryTrade(this.settlementId);
    }
    this.handleGetVerifySwitch("INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT");
  },
  methods: {
    // 查询结算订单
    async queryTrade(id) {
      this.settlementLoading = true;
      const { success, data } = await reqSelectSettlement(id);
      if (success) {
        this.settlement = data;
      }
      this.settlementLoading = false;
    },

    handlerSubmitNo() {
      this.dialogSubmitAllow = false;
      this.handlerSubmit();
    },
    _handlerDelete(id) {
      // console.log(id,'id')
      this.handleRecognizedInvoiceDelete(id);
    },
    handlerBtnStatus(data, recognizes) {
      // console.log(recognizes,'recognizes')
      this.dialogDuplicateCheckingIs = data;
      this.recognizes = recognizes;
      this.batchNoIs = !data;
      this.continueUploading = data;
    },
    async getBatchNo() {
      const batchNoResult = await getRecognizeBatchNo();
      if (!batchNoResult.success) {
        this.toast("发票识别异常！", "error");
        this.loading.close();
        return;
      }
      this.batchNo = batchNoResult.data;
    },
    // 切换图表后在列表里编辑过的内容
    getCurEditInvoiceIds(ids) {
      ids = ids.filter((v) => v != null);
      if (ids.length) {
        this.curEditIds = ids;
      }
    },
    _unqueriedImage() {
      unqueriedImage({ batchNo: this.batchNo, unIds: null }).then((res) => {
        const { success, data } = res;
        if (success) {
          // let map = new Map();
          // for (let i of data) {
          //   if (!map.has(i.invoiceCode) && !map.has(i.invoiceNo)) {
          //     map.set(i.invoiceCode, i);
          //   }
          // }
          // this.unqueriedImageList = [...map.values()];
          this.unqueriedImageList = data;
          this.isComplete = data.map((item) => item.isComplete);
          // console.log(this.isComplete, "this.isComplete");
          this.continueUploading = false;
        }
      });
    },

    /* PDF文件上传 */
    async handleUploadPdfFile(value) {
      const loading = this.handleLoading("PDF解析中，请稍后...");
      // PDF解析请求
      let params = new FormData();
      if (this.isFileUploading) {
        value.file = value.raw;
      }
      params.append("file", value.file);
      const { success, data } = await changePdfToImage(params);
      if (!success) {
        this.toast("PDF解析异常！", "error");
        loading.close();
        return;
      }
      // 解析结果处理
      data.forEach((item) => {
        // Base64图片转文件
        let arr = item.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bytes = atob(arr[1]);
        let n = bytes.length;
        let ia = new Uint8Array(n);
        while (n--) {
          ia[n] = bytes.charCodeAt(n);
        }
        let file = new File([ia], value.file.name, { type: mime });
        // 生成地址
        let url = URL.createObjectURL(file);
        let compressFile = null;
        if (file.size > 1024 * 1024 * 5) {
          imageConversion.compressAccurately(file, 2048).then((res) => {
            compressFile = new File([res], file.name, { type: file.type });
            // console.log(compressFile, '-----compressFile------');
            this.recognizes.push({
              url: URL.createObjectURL(compressFile),
              image: compressFile,
              original: compressFile,
              ifType: "isPdf",
            });
          });
        } else {
          this.recognizes.push({
            url: url,
            image: value.file,
            original: value.file,
            ifType: "isPdf",
          });
        }

        // console.log(url, '-----1');
        // console.log(file, '-----2');
        // console.log(value.file, '----3');
        // this.recognizes.push({ url: url, image: file, original: value.file });
      });
      // if (
      //   this.channel == null ||
      //   this.channel == "" ||
      //   this.channel == "PICTURE"
      // ) {
      //   this.channel = "PDF";
      // }
      loading.close();
    },
    // xml选择
    async handleOnXMLChange(params, type) {
      // console.log(params,'index, type');
      var upload_img = document.getElementsByClassName("upload-file");
      if (upload_img && upload_img.length > 0) {
        var upload = upload_img[0].getElementsByTagName("input");
        if (
          upload &&
          upload.length > 0 &&
          upload[0].files &&
          upload[0].files.length > 0
        ) {
          this.xmlcount = upload[0].files.length;
        }
      }
      this.recognizedCnt++;
      this.loading.close();
      this.dialogDuplicateCheckingIs = false;
      const loading = this.handleLoading("XML识别中，请稍后...");
      const { success, data } = await uploadXMLFiles(params);
      if (!success) {
        this.toast("XML解析异常！", "error");
        loading.close();
        return;
      }
      this.submits = data;
      this.xmlContent = [...this.xmlContent, data];
      this.toast("识别结束！", "success");
      loading.close();
      this.handleRecognizedEnd();
      // if (this.xmlContent.length === this.xmlcount) {
      //   this.$confirm('识别结束,是否继续上传？', '提示', {
      //     confirmButtonText: '返回',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: '/collect/recognize/failure/record',
      //         query: { batchNo: this.batchNo }
      //       });
      //     })
      //     .catch(() => {});
      // }
    },
    handleDeleteShowXMLInvoice(v, i) {
      this.xmlContent = this.xmlContent.filter((item, index) => {
        return index != i;
      });
    },
    /* 图片选择 */
    handleOnChange(file) {
      // console.log(file);
      let url = URL.createObjectURL(file.raw);
      let compressFile = null;
      // 图片大于5M就压缩
      if (file.size > 1024 * 1024 * 5) {
        imageConversion.compressAccurately(file.raw, 2048).then((res) => {
          compressFile = new File([res], file.name, { type: file.type });
          this.recognizes.push({
            url: URL.createObjectURL(compressFile),
            image: compressFile,
            original: compressFile,
          });
        });
      } else {
        this.recognizes.push({ url: url, image: file.raw, original: file.raw });
      }
    },
    /* 删除展示图片 */
    handleDeleteShowInvoice(url) {
      this.recognizes.some((item, i) => {
        if (item.url == url) {
          this.recognizes.splice(i, 1);
          return true;
        }
      });
    },
    handlerSelectFile(file) {
      let xmlTesx = /xml$/;
      let pdfTesx = /pdf$/;
      let ofdTesx = /ofd$/;
      this.batchDiscern = true;
      if (xmlTesx.test(file.name)) {
        let url = URL.createObjectURL(file.raw);
        this.recognizes.push({
          url: url,
          image: file.raw,
          original: file.raw,
          ifType: "ifXml",
        });
      } else if (pdfTesx.test(file.name)) {
        this.isFileUploading = true;
        this.handleUploadPdfFile(file);
      } else if (ofdTesx.test(file.name)) {
        let url = URL.createObjectURL(file.raw);
        this.recognizes.push({
          url: url,
          image: file.raw,
          original: file.raw,
          ifType: "ifOfd",
        });
      } else {
        let url = URL.createObjectURL(file.raw);
        let compressFile = null;
        // 图片大于5M就压缩
        if (file.size > 1024 * 1024 * 5) {
          imageConversion.compressAccurately(file.raw, 2048).then((res) => {
            compressFile = new File([res], file.name, { type: file.type });
            this.recognizes.push({
              url: URL.createObjectURL(compressFile),
              image: compressFile,
              original: compressFile,
              ifType: "ifImg",
            });
          });
        } else {
          this.recognizes.push({
            url: url,
            image: file.raw,
            original: file.raw,
            ifType: "ifImg",
          });
        }
      }
      // console.log(this.recognizes,'this.recognizes')
    },
    // 开始识别弹框
    duplicateChecking() {
      if (!this.org.orgId) {
        this.toast("请选择收票组织！", "warning");
        return;
      }
      this.dialogDuplicateCheckingIs = true;
    },
    async ofdRecognize() {
      let res = await ofdRecognize(this.paramsData);
      // console.log(res,'res')
      this.recognizedCnt++;
      this.loading.close();
      this.dialogDuplicateCheckingIs = false;
      const loading = this.handleLoading("OFD识别中，请稍后...");
      if (res.success) {
        this.submits = res.data;
      } else {
        this.toast("OFD解析异常！", "error");
        loading.close();
        return;
      }
      loading.close();
      this.toast("识别结束！", "success");
      this.handleRecognizedEnd();
    },
    /* 开始识别 */
    async handleStartRecognize(type) {
      this.repetitionWhether = type;
      // console.log(this.recognizes,'this.recognizes-识别')
      // 识别加载
      this.loading = this.handleLoading("发票识别中，请稍后...");

      if (this.batchNoIs) {
        // 获取批次编号
        const batchNoResult = await getRecognizeBatchNo();
        if (!batchNoResult.success) {
          this.toast("发票识别异常！", "error");
          this.loading.close();
          return;
        }
        this.batchNo = batchNoResult.data;
        // 重置已识别数量
        this.recognizedCnt = 0;
      }
      this.$message({
        duration: 0,
        message: "正在识别，请勿离开页面！",
        type: "warning",
      });

      // 开始识别
      for (let [index, recognize] of this.recognizes.entries()) {
        // 开始识别
        setTimeout(() => {
          // console.log(`正在识别第${index + 1}张图片...`);
          var params = new FormData();
          params.append("orgId", this.org.orgId);
          params.append("source", this.source);
          params.append("invoiceLabel", this.invoiceLabel);
          params.append("batchNo", this.batchNo);
          params.append("batchIndex", (index + 1).toString());
          params.append("image", recognize.image);
          params.append("original", recognize.original);
          params.append("fileFrom", "UPLOAD");
          params.append("noNeedToCheckRepeat", type);
          params.append("settlementId", this.settlementId);
          if (recognize.ifType == "ifOfd") {
            this.channel = "OFD";
            params.append("channel", this.channel);
            this.paramsData = params;
            this.ofdRecognize();
          } else if (recognize.ifType == "ifXml") {
            this.channel = "XML";
            params.append("channel", this.channel);
            this.handleOnXMLChange(params, index);
          } else {
            if (recognize.ifType == "ifImg") {
              this.channel = "IMG";
            } else {
              this.channel = "PDF";
            }
            params.append("channel", this.channel);
            if (typeof this.settlementId != "undefined") {
              params.append("settlementId", this.settlementId);
            }
            // 图片-pdf 识别接口
            this.handleRecognizeResult(params, index);
          }
        }, index * 1500);
      }
    },
    /* 发票识别结果处理 */
    handleRecognizeResult(params, index) {
      // 识别请求
      recognizeInvoicePicture(params).then((res) => {
        const { success, data } = res;
        // console.log(`第${index + 1}张图片识别【${success ? 'Y' : 'N'}】`);
        this.recognizedCnt++;
        this.loading.close();
        if (success) {
          // 识别结果
          for (let invoice of data) {
            if (invoice.invoiceType == "VatInvoiceList") {
              // 同批次识别校验
              let repeatIndex = this.submits.findIndex(
                (item) =>
                  item.invoiceCode == invoice.invoiceCode &&
                  item.invoiceNo == invoice.invoiceNo &&
                  item.invoiceType == "VatInvoiceList" &&
                  item.pageIndex == invoice.pageIndex
              );
              // if (repeatIndex != -1) invoice.status = 'REPEAT';

              // 发票数据校验
              if (invoice.pageSize == "" || invoice.pageIndex == "")
                invoice.status = "FAILURE";

              // 同批次内匹配发票
              this.submits.forEach((item) => {
                if (
                  item.invoiceCode == invoice.invoiceCode &&
                  item.invoiceNo == invoice.invoiceNo &&
                  item.invoiceType != "VatInvoiceList" &&
                  invoice.status == "UNMATCHED"
                ) {
                  invoice.status = "SUCCESS";
                }
              });
            } else {
              // 同批次识别校验
              let repeatIndex = "";
              if (
                invoice.invoiceType != "TrainInvoice" &&
                invoice.invoiceType != "PlaneInvoice"
              ) {
                repeatIndex = this.submits.findIndex(
                  (item) =>
                    item.invoiceCode == invoice.invoiceCode &&
                    item.invoiceNo == invoice.invoiceNo &&
                    item.invoiceType != "VatInvoiceList" &&
                    item.imageChineseKey == invoice.imageChineseKey &&
                    item.imageDeductionChineseKey ==
                      invoice.imageDeductionChineseKey
                );
              } else {
                repeatIndex = this.submits.findIndex(
                  (item) => item.train21No == invoice.train21No
                );
              }
              // if (repeatIndex != -1) invoice.status = 'REPEAT';

              // 发票数据校验
              if (
                invoice.invoiceType != "TrainInvoice" &&
                invoice.invoiceType != "PlaneInvoice"
              ) {
                // 全电票没有发票代码
                if (
                  invoice.invoiceType == "FullEleSpecInvoice" ||
                  invoice.invoiceType == "FullEleGenerInvoice"
                ) {
                  if (invoice.eInvoiceNo == "") {
                    invoice.status = "FAILURE";
                  }
                } else {
                  if (invoice.invoiceCode == "" || invoice.invoiceNo == "")
                    invoice.status = "FAILURE";
                }
              }

              // 同批次内匹配发票
              let invoiceLists = this.submits.filter(
                (item) =>
                  item.invoiceCode == invoice.invoiceCode &&
                  item.invoiceNo == invoice.invoiceNo &&
                  item.invoiceType == "VatInvoiceList" &&
                  item.status == "UNMATCHED"
              );

              if (invoiceLists.length != 0) {
                invoiceLists.forEach((item) => (item.status = "REPEAT"));

                const hash = {};
                let templateList = invoiceLists.reduceRight((item, next) => {
                  if (!hash[next.pageIndex])
                    hash[next.pageIndex] = true && item.push(next);
                  return item;
                }, []);
                templateList.forEach((item) => (item.status = "SUCCESS"));
              }
            }
            // 发票是否已存在
            if (!this.repetitionWhether) {
              let idIndex = this.submits.findIndex(
                (item) => item.id == invoice.id
              );
              if (idIndex == -1) this.submits.push(invoice);
            } else {
              // console.log(this.submits,'this.submits')
              this.submits = data;
            }
          }
          // console.log(this.submits,'this.submits')
        }
        this.dialogDuplicateCheckingIs = false;
        this.toast("识别结束！", "success");
        // 识别结束判断
        this.handleRecognizedEnd();
      });
    },
    /* 识别结束 */
    handleRecognizedEnd() {
      // console.log(`判断是否识别结束，图片张数${this.recognizes.length} 识别张数${this.recognizedCnt}`);
      // 识别结束
      if (
        this.recognizedCnt == this.recognizes.length ||
        this.continueUploading
      ) {
        // console.log(`识别结束！！！ 图片张数${this.recognizes.length} 识别张数${this.recognizedCnt}`);
        const successLength = this.submits.filter(
          (item) => item.status == "SUCCESS"
        ).length;
        this.isSubmit = false;
        this.toast("识别结束！", "success");
        this._unqueriedImage();
        this.handleGetVerifySwitch("INV_RECEIVE_SHOWCASE");
      }
    },
    /* 识别发票删除 */
    handleRecognizedInvoiceDelete(id) {
      deleteImageInvoice(id).then((res) => {
        if (res.success) {
          this.submits.forEach((item, index) => {
            if (item.id == id) {
              this.submits.splice(index, 1);
              if (
                this.submits.filter((item) => item.status == "SUCCESS")
                  .length == 0
              ) {
                window.location.reload();
              }
            }
          });
          this.$message.closeAll();
          if (this.submits.length > 0) {
            this.toast("删除成功", "success");
          }
        } else {
          this.toast("删除失败！", "error");
        }
      });
    },
    /* 发票提交 */
    async handleSubmitInvoice() {
      let isComplete = this.isComplete.every((item) => {
        return item == true;
      });
      if (!isComplete) {
        if (this.allowSybmit) {
          this.dialogSubmitAllow = true;
        } else {
          this.dialogSubmitNotAllow = true;
        }
      } else {
        this.handlerSubmit();
      }
    },
    // 发票清单不全允许提交
    async handleGetVerifySwitch(type) {
      await getTntConfig(type).then(({ success, data }) => {
        if (type == "INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT") {
          this.allowSybmit = data == "Y" ? true : false;
        } else if (type == "INV_RECEIVE_SHOWCASE") {
          // console.log(data, "data_111");
          this.btnStatus = data == "LIST" ? false : true;
        }
      });
    },
    // 提交
    async handlerSubmit() {
      const loading = this.handleLoading();
      const tmpIds = [
        ...new Set([
          ...this.submits
            .filter((item) => item.status == "SUCCESS")
            .map((item) => item.id),
          ...this.curEditIds,
        ]),
      ];
      let param = {
        ids: tmpIds,
        batchNo: this.batchNo,
        source: this.source,
        channel: this.channel,
        invoiceLabel: this.invoiceLabel,
        sceneName: this.sceneName,
        sceneRole: this.sceneRole,
        oriTradeId: this.settlementId,
      };
      // 发票提交请求
      const { success } = await submitInvoice(param);
      if (!success) {
        this.toast("发票提交异常！", "error");
        loading.close();
        return;
      }

      // 发票提交成功处理
      this.successVisible = true;
      if (this.isSettlement) {
        if (this.channel == "SUPPLIER") {
          this.successTitle = "返回结算开票";
        } else {
          if (this.isBlend) {
            this.successTitle = "返回匹配列表";
          } else {
            this.successTitle = "返回上传列表";
          }
        }
      }
      loading.close();
    },
    /* OFD上传弹窗 */
    handleCloseUpload(batchNo) {
      this.batchNo = batchNo;
      this.handleBackInvoiceCenter("back");
    },
    handleContinueUploadJX() {
      this.successVisible = false;
      window.location.reload();
    },
    /* 处理失败发票 */
    handleFailureInvoice(value) {
      if (value == "F" || value == "R") {
        this.recognizeLeave = true;
        this.recognizeLeaveType = value;
      } else {
        let status = "";
        if (this.recognizeLeaveType == "F") status = "FAILURE";
        if (this.recognizeLeaveType == "R") status = "REPEAT";
        // 跳转页面
        this.$router.push({
          path: "/collect/recognize/failure/record",
          query: { batchNo: this.batchNo, status: status },
        });
      }
    },
    /* 返回收票中心 */
    handleBackInvoiceCenter(param, ofdResult = false) {
      if (this.channel == "SUPPLIER") {
        // 结算开票
        this.$router.push("/supplier/settlement");
        if (!ofdResult) {
          this.toast("已上传发票，请在票单匹配表中查看稽查结果", "success");
        }
      } else if (this.channel == "RETAILER") {
        if (this.isBlend) {
          // 结算收票
          this.$router.push({
            path: "/retailer/settlement/invoice-match",
            query: { batchNo: window.btoa(this.batchNo), type: "retailer" },
          });
        } else {
          this.isSettlement = false;
          this.$router.push({
            path: "/retailer/settlement/uploadInvoiceList",
            query: { id: window.btoa(this.settlementId), type: "retailer" },
          });
        }
      } else {
        if (this.module == "other") {
          this.$router.push("/collect/other/invoice");
        } else {
          this.$router.push("/collect/list");
        }
      }
    },
    /* 页面销毁前操作 */
    beforeDestroy() {
      this.$message.closeAll();
      this.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-cont {
  padding: 20px 20px 8px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;

  .cont-item {
    width: 360px;
    font-size: 14px;
    margin-bottom: 12px;
    display: inline-flex;

    label {
      font-weight: 500;
      color: #888888;
      display: inline-flex;
    }

    span {
      color: #555555;
      display: inline-flex;
    }
  }
}

.content-select {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  .select-upload {
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;

    .select-left {
      display: flex;
      margin-left: 20px;

      .upload {
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          width: 16px;
          height: 16px;
        }

        .el-button {
          padding: 0;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 20px;
          margin-right: 10px;
        }
      }

      .el-button:hover {
        color: #1890ff;
      }
    }

    .select-right {
      margin-right: 30px;
      display: flex;
    }
  }
  .collect_item_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: rgba(230, 247, 255, 0.6);
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #91d5ff;
    width: 100%;
  }
}

.select-recognize {
  width: 100%;
  display: flex;
  align-items: center;

  .collect_item {
    padding-right: 10px;
    line-height: 14px;
  }
  .collect_item + .collect_item {
    border-left: 1px solid #3d3d3d;
    padding: 0 10px;
    font-size: 14px;
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  img {
    width: 104px;
    height: 98px;
    margin-top: 120px;
  }

  span {
    width: 108px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
    margin-top: 9px;
  }
}

.picture-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-x: auto;
  margin: 24px auto;
  height: 85%;

  .content-upload {
    .content-tips {
      display: flex;
      margin: 24px;
      background-color: #ebf4ff;
      align-items: center;
      padding-left: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      .el-button {
        padding: 0;
      }
    }

    .exist-data {
      display: flex;
      flex-wrap: wrap;
      padding-left: 30px;

      .show-container {
        position: relative;

        img {
          width: 20px;
          height: 20px;
          margin: 20px 250px;
          position: absolute;
          z-index: 2;
        }

        .show-invoice {
          width: 260px;
          height: 160px;
          border: 2px solid #cccccc;
          margin: 12px 15px;
          z-index: 1;
          .xml_con_box {
            padding: 60px 0 0 20px;
            text-align: left;
          }
          .el-image {
            width: 260px;
            height: 160px;
          }

          .image-mask {
            display: none;
          }
        }
      }
    }
  }

  .content-recognize {
    .recognize-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding: 16px 24px;
      margin-bottom: 24px;

      .recognize-left {
        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }
    }

    .recognize-body {
      display: grid;
      margin: 0px 50px;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.table_content {
  margin-top: -24px;
}

.dialog-success {
  margin: 24px;
}

.dialog-leave {
  margin: 24px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}
.submit_Allow_style {
  ::v-deep .el-dialog__footer {
    text-align: center;
    padding: 10px 20px 20px;
  }
  ::v-deep .el-dialog__header {
    padding: 24px 24px 10px 24px;
  }
}
</style>
